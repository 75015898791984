import { t } from 'i18next'
import axios from 'axios'

import { convertDate } from '../utils/helpers'

export async function fetchFeedbackQuestions (dossierId) {
  let result = {}
  let allQuestions = []

  let options = {
    url: `https://jsn.tourbase.letsgo.ch/dossiers/${dossierId}/`,
    method: 'get',
    crossDomain: true
  }
  result = await axios(options)

  allQuestions.push(
    getAirlines(result.data.dossier.Dossier),
    getHotels(result.data.dossier.Dossier),
    getTrips(result.data.dossier.Dossier),
    getTours(result.data.dossier.Dossier)
  )

  const questions = allQuestions.filter(({ items }) => items.length)

  return questions
}

export async function fetchFeedbackMeta (dossierId) {
  let result = {}

  let options = {
    url: `https://jsn.tourbase.letsgo.ch/dossiers/${dossierId}/`,
    method: 'get',
    crossDomain: true
  }
  result = await axios(options)

  const dossier = result.data.dossier.Dossier
  const firstDate = convertDate(dossier.FirstDate)
  const lastDate = convertDate(dossier.LastDate)

  return {
    title: `${dossier.Title}, ${firstDate} - ${lastDate}`,
    travellers: dossier.Travellers.Traveller.map(
      t => `${t.FirstName} ${t.LastName}`
    ).join(', '),
    dossierNumber: dossier.DossierNr,
    employeeCode: dossier.Code1['@attributes'].Code
  }
}

export async function postFeedback (answer) {
  let result = {}

  let options = {
    url: `https://jsn.tourbase.letsgo.ch/feedbacks/`,
    method: 'post',
    data: answer,
    crossDomain: true
  }

  result = await axios(options)

  return result
}

function getHotels (dossier) {
  const likes = ['accommodation', 'service', 'food', 'location', 'staff']
  const rating = 5

  let hotels = {
    step: 'hotels',
    title: t('feedback.hotels.title'),
    subtitle: t('feedback.hotels.subtitle'),
    rating,
    likes,
    items: []
  }

  const rawItems =
    dossier.DossierItems.DossierItem.filter(
      item => item['@attributes'].Type === 'Hotel' && item.Hotel
    ) || []

  const allHotelItems = rawItems.map(
    ({
      '@attributes': attributes,
      ShortDescription1,
      BeginDate,
      EndDate,
      Hotel
    }) => {
      return {
        id: `${Hotel.HotelName} ${Hotel.Destination}${Hotel.HotelCode || ''}`,
        location: [
          Hotel.Country['@attributes'].nodeValue,
          Hotel.Location,
          Hotel.City
        ],
        topic: Hotel.HotelName,
        beginDate: convertDate(BeginDate),
        endDate: convertDate(EndDate),
        serviceName: ShortDescription1,
        destCode: Hotel.Destination,
        serviceCode: Hotel.HotelCode || '',
        mergedCode: `${Hotel.Destination}${Hotel.HotelCode || ''}`,
        sortOrder: attributes.InvoicePosition
      }
    }
  )

  hotels.items = removeDuplicates(allHotelItems, 'id')
  hotels.items.sort((a, b) => a.sortOrder - b.sortOrder)

  return hotels
}

function getAirlines (dossier) {
  const rating = 5

  let airlines = {
    step: 'airlines',
    title: t('feedback.airlines.title'),
    subtitle: t('feedback.airlines.subtitle'),
    rating,
    items: []
  }

  // flights that are in package as an array on single object
  let nestedFlights = []

  dossier.DossierItems.DossierItem.forEach(item => {
    if (item['@attributes'].Type === 'Package' && item.Package.PackageItems) {
      nestedFlights = nestedFlights.concat(
        item.Package.PackageItems.DossierItem.filter(
          item => item['@attributes'].Type === 'Flight'
        )
      )
    }
  })

  // flights that are on top of dossier as flight type object
  const topFlights =
    dossier.DossierItems.DossierItem.filter(
      item => item['@attributes'].Type === 'Flight'
    ) || []

  const rawItems = [...nestedFlights, ...topFlights]
  const allAirlineItems = rawItems.map(
    ({ ShortDescription1, BeginDate, EndDate, Flight }) => {
      return {
        id: Flight.Airlinecode['@attributes'].AirlineName,
        topic: Flight.Airlinecode['@attributes'].AirlineName,
        beginDate: convertDate(BeginDate),
        endDate: convertDate(EndDate),
        serviceName: ShortDescription1,
        serviceCode: `${Flight.Airlinecode['@attributes'].nodeValue} ${Flight.Airlinecode['@attributes'].AirlineName}`
      }
    }
  )

  airlines.items = removeDuplicates(allAirlineItems, 'id')

  return airlines
}

function getTrips (dossier) {
  const rating = 5

  let trips = {
    step: 'trips',
    title: t('feedback.trips.title'),
    subtitle: t('feedback.trips.subtitle'),
    rating,
    items: []
  }

  const rawItems =
    dossier.DossierItems.DossierItem.filter(
      item =>
        item['@attributes'].Type === 'Misc' &&
        item.Misc &&
        item.Misc.MiscCode &&
        item.Misc.MiscCode.startsWith('EXC')
    ) || []

  trips.items = rawItems.map(({ ShortDescription1, BeginDate, Misc }) => {
    return {
      id: Misc.MiscCode,
      location: [Misc.Country['@attributes'].nodeValue],
      topic: Misc.Title,
      beginDate: convertDate(BeginDate),
      endDate: '',
      serviceName: ShortDescription1,
      destCode: Misc.Destination,
      serviceCode: Misc.MiscCode
    }
  })

  return trips
}

function getTours (dossier) {
  const likes = ['guide', 'driver', 'accommodations', 'duration', 'comfort']
  const rating = 5

  let tours = {
    step: 'tours',
    title: t('feedback.tours.title'),
    subtitle: t('feedback.tours.subtitle'),
    rating,
    likes,
    items: []
  }

  const rawItems =
    dossier.DossierItems.DossierItem.filter(
      item =>
        item['@attributes'].Type === 'Package' &&
        item.ShortDescription1.startsWith('RTP')
    ) || []

  tours.items = rawItems.map(
    ({ ShortDescription1, BeginDate, EndDate, Package }) => {
      return {
        id: Package.PackageCode || Package.Title,
        topic: Package.Title,
        beginDate: convertDate(BeginDate),
        endDate: convertDate(EndDate),
        serviceName: ShortDescription1,
        destCode: Package.Destination,
        serviceCode: Package.PackageCode || Package.Title
      }
    }
  )

  return tours
}

function removeDuplicates (myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}
